<template>
	<div class="my-profile">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back route="MyProfile" />
		</div><!-- header -->
		<div class="header-image grey"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="NotificationIcon"
				subtitle="My Profile"
				title="Notification Preferences"
				colour="profile profile-red"
			/>
			<div class="toggle-container section-gap">
				<span class="eyebrow noti-eyebrow">What notifications would you like to receive?</span>
				<div class="toggle-wrap">
					<div class="text-icon">
						<div class="icon-hold">
							<SendIcon style="width: 25px;" class="send-icon" />
						</div>
						<span>Requests</span>
					</div>
					<b-field>
			            <b-switch v-model="form.requests" type="is-success"></b-switch>
			        </b-field>
				</div>
				<div class="toggle-wrap">
					<div class="text-icon">
						<div class="icon-hold">
							<FolderIcon style="width: 22px;" class="send-icon" />
						</div>
						<span>Resources</span>
					</div>
					<b-field>
			            <b-switch v-model="form.resources" type="is-success"></b-switch>
			        </b-field>
				</div>
				<div class="toggle-wrap">
					<div class="text-icon">
						<div class="icon-hold">
							<NewsIcon style="width: 22px;" class="send-icon" />
						</div>
						<span>News</span>
					</div>
					<b-field>
			            <b-switch v-model="form.news" type="is-success"></b-switch>
			        </b-field>
				</div>
				<div class="toggle-wrap">
					<div class="text-icon">
						<div class="icon-hold">
							<HappyIcon style="width: 24px;" class="send-icon" />
						</div>
						<span>Happy at Sea</span>
					</div>
					<b-field>
			            <b-switch v-model="form.his" type="is-success"></b-switch>
			        </b-field>
				</div>
			</div>
            <Button @click.native="updateNotificationPreferences" icon="ArrowRightIcon" class="none-bottom btn btn-med btn-green btn-tick btn-square btn-icon btn-icon-right" text="Update Notification Preferences"></Button>
		</div>
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import SendIcon from '@/assets/send.svg';
	import NewsIcon from '@/assets/news.svg';
	import FolderIcon from '@/assets/folder.svg';
	import HappyIcon from '@/assets/happy.svg';
    import UserService from '@/services/UserService';
    import Button from '@/components/Button.vue';
	export default {
		name: 'MyProfileNotificationPreferences',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle,
			SendIcon,
			NewsIcon,
			FolderIcon,
			HappyIcon,
            Button
		},
		data () {
	        return {
                form: {
                    requests: false,
                    resources: false,
                    news: false,
                    his: false
                }
	        };
	    },
	    methods: {
            getUser () {
                UserService.getUser().then((response) => {
                    this.form.requests = !!response.data.notifications_requests;
                    this.form.resources = !!response.data.notifications_resources;
                    this.form.news = !!response.data.notifications_news;
                    this.form.his = !!response.data.notifications_his;
                });
            },
            updateNotificationPreferences () {
                UserService.updateNotificationPreferences({
                    requests: this.form.requests,
                    resources: this.form.resources,
                    news: this.form.news,
                    his: this.form.his
                }).then(() => {
                    alert('Notification Preferences updated!');
                    this.$router.push('/my-profile');
                });
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {
            this.getUser();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.noti-eyebrow {
		text-align: left;
		margin-bottom: 20px;
	}
	.section-title {
		&.red {
			color: #C83254;
		}
	}
	.toggle-container {
		display: block;
		.toggle-wrap {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid $snow;
			padding-bottom: 12px;
			margin-bottom: 12px;
			.field {
				margin-bottom: 0;
				label {
					margin: 0!important;
    				display: block;
				}
			}
		}
		.text-icon {
			display: flex;
			align-items: center;
			.icon-hold {
				width: 28px;
				margin-right: 15px;
			}
			svg {
				display: block;
				path, .no-eyes {
					stroke-width: 3;
					stroke: $grey;
				}
				.eyes {
					stroke-width: 3;
					fill: $grey;
				}
			}
			span {
				color: $dark;
				font-weight: $weight_600;
				font-size: 17px;
				letter-spacing: -0.43px;
				line-height: 20px;
			}
		}
	}
</style>
